document.addEventListener('DOMContentLoaded', function () {

    console.log('DOM fully loaded and parsed');

// Define the ScrollReveal instance
ScrollReveal().reveal('.scroll-fade', {
    // Define the distance of the initial position
    distance: '0',
    // Set the opacity to 0 at the start of the animation
    opacity: 0,
    // Define the duration of the animation
    duration: 800,
    // Ease in and out
    easing: 'ease-in-out',
    // Define the direction of the initial position (default is 'bottom', so elements will rise up)
    origin: 'bottom',
    // Apply a delay interval between each element (in milliseconds)
    interval: 500,
    // Set the final opacity to 1, so the elements fade in completely
    scale: 1,
  });
        

//Intialize the splide slider
   var splide = new Splide('#splide-slider', {
        perPage: 3,
        pagination: false,
        rewind: true,
        gap: '50px',
        breakpoints: {
          992: {  // Change to 2 slides per page when window width is less than 992px
              perPage: 2,
          },
          600: {  // Change to 1 slide per page when window width is less than 600px
              perPage: 1,
          }
      }
    }).mount();

    // Function to check and update arrow visibility
    function updateArrows() {
      var perPage = splide.options.perPage;
      var arrows = document.querySelectorAll('.splide__arrow');

      if (splide.length <= perPage) {
          arrows.forEach(function(arrow) {
              arrow.style.display = 'none';
          });
      } else {
          arrows.forEach(function(arrow) {
              arrow.style.display = '';
          });
      }
  }

  // Initial check
  updateArrows();

  // Update on resize
  window.addEventListener('resize', updateArrows);

  // Update on orientation change (for mobile devices)
  window.addEventListener('orientationchange', updateArrows);



}); // DOMContentLoaded

